import React from "react"
// - import { Link } from "gatsby"
// +
import Link from "../components/link"
import  {FeatureImage}  from '../components/Image';
import {ThemeContext, Box, Heading, Paragraph, Text } from "grommet"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react'
import Layout from "../components/layout"
import SEO from "../components/seo"

const _ = require("lodash")

const myPar = {
  paragraph: {
    extend: `
      font-weight: 300;`
  }
};

const myParTop = {
  paragraph: {
    extend: `
      font-weight: 700;`
  }
};

const components = {

  p: Paragraph,
}

const IndexPage = ({ data, pageContext }) => {
  const { post, coverImageUrl } = data;

  const { featuredImage, title, subtitle, author, date,category } = post.frontmatter;

  return (

  <Layout>
    <SEO title="Home" />

    <Box  align="center" width="large" >

      <Link  to={`/blog/category/${_.kebabCase(category)}`}  margin={{ top: 'large' }}>{post.frontmatter.category}</Link>
      <Heading textAlign="center"  margin={{ vertical: 'large' }}>{post.frontmatter.title}</Heading>
      <Text>Written by</Text>
      <Text margin={{ bottom: 'medium' }}>{post.frontmatter.author}</Text>
      <Text color="dark-3" margin={{ bottom: 'large' }}>{post.frontmatter.date}</Text>
    </Box>

    <Box  align="center" width="xlarge" >
        <FeatureImage src={featuredImage} alt={title} />
    </Box>

    <Box align="start" width="large"  margin={{ vertical: 'large' }}>
        <ThemeContext.Extend value={myPar}>
           <MDXProvider components={components}>
             <MDXRenderer>{post.body}</MDXRenderer>
             </MDXProvider>
        </ThemeContext.Extend>
    </Box>
  </Layout>

);
};

export const query = graphql`
  query($id: String!) {
    post: mdx(id: { eq: $id }) {
      id
      body

      frontmatter {
        title
        category
        author
        date
        featuredImage
      }
    }


  }
`;
export default IndexPage
